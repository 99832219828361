import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import Logo from './Logo';

const Navbar = () => {
  return (
    <AppBar position="static">
      <Toolbar variant="dense">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Logo />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;