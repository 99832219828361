import React from 'react';
import Navbar from './components/Navbar';
import {Container, Typography, Box, Link} from '@mui/material';


const App = () => {
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Navbar />
        <Container maxWidth='md' minWidth='sm' sx={{
            padding:1,
            marginTop: 1,
        }}>
            <Box sx={{
                marginTop: 1,
            }}>
                <Typography variant="h3">
                    Carriers.fyi is now Carrier Insights!
                </Typography>
            </Box>
            <Box sx={{
                marginTop: 1,
            }}>
                <Typography>
                    Carrier Insights is included in all Parade subscriptions.
                </Typography>
            </Box>
            <Box sx={{
                marginTop: 1,
            }}>
                <Link href="https://app.parade.ai">Go to Parade</Link>
            </Box>
            <Box sx={{
                marginTop: 1,
            }}>
                <Link href="https://help.parade.ai/en/articles/9894161-9-18-2024-carrier-insights">Read More</Link>
            </Box>
            <Box sx={{
                marginTop: 1,
            }}>
                <Link href="https://try.parade.ai/contact-parade">Contact Sales</Link>
            </Box>
        </Container>
    </div>
  );
};

export default App;